body {
  margin: 0;
  padding: 0;
  background-color: #282c36;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  text-align: left;
  margin-top: 120px;
  margin-left: 60px;
  background-color: #282c36;
  color: yellow;
}

img {
  width: 100%;
  height: auto;
  border-radius: 7px;
}
.brand_logo {
  height: 44px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}
/* nav{
  position: fixed;
  text-align: right;
  top: 0;
  padding: 30px 0px;
  width: 100%;
  background-color: #282c36;
}
nav a{
  font-size: 10px;
  text-decoration: none;
  margin: 10px 10px;
  padding: 13px 20px;
  border-radius: 8px;
  font-weight: bold;
  color: white;
}
nav a:visited{
  color: white;
} */